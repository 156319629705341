/*
Auto-generated by: https://github.com/pmndrs/gltfjsx

*/
import { useGLTF, useAnimations } from '@react-three/drei'
import React, { useRef, useState, useEffect } from 'react'
import * as THREE from 'three'
import { useSpring, useTransition, animated,config } from '@react-spring/three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { useFrame, useLoader} from '@react-three/fiber'
import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'





export default function Sunmoon({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('./sunmoon/scene.glb')
  const { actions } = useAnimations(animations, group)
  const [mixer] = useState(() => new THREE.AnimationMixer())

  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])
  useFrame((state, delta) => {
      const elap = state.clock.getElapsedTime()
      mixer.update(delta)
    });






    const [hovered, sethovered] = useState(false)
    const [starter, setsstarter] = useState(false)
    const [colorfrom, setcolorfrom] = useState('#000000')
    const [counter, setcounter] = useState()
    const zep_col_dark = (props.daynight ? '#ef810e' : '#012a4a')
    const zep_col_light = (props.daynight ? '#012a4a' : '#ef810e')
    const [colorto, setto] = useState('#000000')
    console.log(props.daynight)
    useEffect(() => {

      let intervalId;
      let perc = 100;
      if(props.clicked){
        sethovered(true)
      }
      if((hovered)&&(starter)){
        setcounter(perc)
        setto(zep_col_dark)
        setcolorfrom(zep_col_light)
      }
      if (hovered && (starter==false)){
        setsstarter(true)
        setcounter(0)
        setto(zep_col_dark)
        setcolorfrom(zep_col_light)
      }
      if(starter){
        intervalId = setInterval(() => {
          setcounter(counter + 1);
        }, 1)
      }
      if (counter < perc){
        var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
        var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
        var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
        var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
        setcolorfrom(c)
      }else{
        setsstarter(false)
      }
     document.body.style.cursor = hovered ? "pointer" : "auto"
     return () => clearInterval(intervalId);
   }, [hovered,props.clicked,props.daynight,counter])
    const material = new THREE.MeshPhongMaterial({
      color: colorfrom,    // red (can also use a CSS color string here)
      flatShading: true,
    });





  return (
    <group ref={group} {...props} dispose={null} scale={.05}
    onPointerOver={(e) => (e.stopPropagation(), sethovered(true))}
    onPointerOut={(e) => (e.stopPropagation(), sethovered(false))}

    >
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group
            position={[3.47, -4.25, 0]}
            rotation={[0, 0, 0]}
            scale={[110.2, 23.5, 110.2]}>
            <mesh geometry={nodes.Sun_and_Moon_Stones_Moonstone_0.geometry} material={material} />
            <mesh geometry={nodes.Sun_and_Moon_Stones_Sunstone_0.geometry} material={material} />
          </group>
        </group>
      </group>
    </group>
  )
}
