import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Main from './main.js';

import './fonts/Tribeca.ttf';
// ========================================

ReactDOM.render(
  <Main />,
  document.getElementById('root')
);
