/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Bruce (https://sketchfab.com/brucemevans)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/models/727013a82a7a4fa38adbfdeae736816e
title: Polaroid Land Camera - Low Poly
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useSpring, animated } from '@react-spring/three'

import { hextorgb, rgbtohex} from './miscelaneous'

export default function Camera({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF('./camera/scene.gltf')

  const [hovered, sethovered] = useState(false)
  const [starter, setsstarter] = useState(false)
  const [colorfrom, setcolorfrom] = useState('#000000')
  const [counter, setcounter] = useState()
  const zep_col_dark = '#e60012'
  const zep_col_light = '#ff2022'
  const [colorto, setto] = useState('#000000')

  useEffect(() => {

    let intervalId;
    let perc = 100;
    if(props.clicked){
      sethovered(true)
    }
    if((hovered)&&(starter)){
      setcounter(perc)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if (hovered && (starter===false)){
      setsstarter(true)
      setcounter(0)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if(starter){
      intervalId = setInterval(() => {
        setcounter(counter + 1);
      }, 1)
    }
    if (counter < perc){
      var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
      var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
      var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
      var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
      setcolorfrom(c)
    }else{
      setsstarter(false)
    }
   document.body.style.cursor = hovered ? "pointer" : "auto"
   return () => clearInterval(intervalId);
 }, [starter,colorfrom,colorto,hovered,props.clicked,counter])

  const material = new THREE.MeshPhongMaterial({
    color: colorfrom,    // red (can also use a CSS color string here)
    flatShading: true,

  });




  const styles = useSpring({
    loop: true,
    from: { rotation: [.1, -.1, -Math.PI / 2 ],
             position: [0,.1,0],
           },
    to: { rotation: [-.1, .1, -Math.PI],
          position: [0,-.1,0],
          },
    config:{ mass: 100, tension: 100, friction: 0 }
  });






  let hover_over = (e) => {
    e.stopPropagation()
    sethovered(true)
  }
  let hover_off = (e) => {
    e.stopPropagation()
    sethovered(false)
  }

  return (

      <group ref={group} {...props} dispose={null} onPointerOver={(e) => hover_over(e)}
      onPointerOut={(e) => hover_off(e)}
      >
        <group rotation={[-Math.PI / 2, 0, 0]} scale={10}>
          <animated.mesh castShadow receiveShadow { ...styles}>
            <mesh geometry={nodes.mesh_0.geometry} material={material} />
          </animated.mesh>

        </group>
      </group>

  )
}

useGLTF.preload('/scene.gltf')
