/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: inesmerino (https://sketchfab.com/inesmerino)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/models/ac4fc7339cd643aa93a0ea0c5b12544c
title: Computer
*/



import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useSpring, useTransition, animated,config } from '@react-spring/three'

import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'

export default function Computer({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('./computer/scene.gltf')
  const scale = 5



    const [hovered, sethovered] = useState(false)
    const [starter, setsstarter] = useState(false)
    const [colorfrom, setcolorfrom] = useState('#000000')
    const [counter, setcounter] = useState()
    const zep_col_dark = '#6535B2'
    const zep_col_light = '#8169fB'
    const [colorto, setto] = useState('#000000')
    useEffect(() => {

      let intervalId;
      let perc = 100;
      if(props.clicked){
        sethovered(true)
      }
      if((hovered)&&(starter)){
        setcounter(perc)
        setto(zep_col_dark)
        setcolorfrom(zep_col_light)
      }
      if (hovered && (starter==false)){
        setsstarter(true)
        setcounter(0)
        setto(zep_col_dark)
        setcolorfrom(zep_col_light)
      }
      if(starter){
        intervalId = setInterval(() => {
          setcounter(counter + 1);
        }, 1)
      }
      if (counter < perc){
        var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
        var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
        var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
        var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
        setcolorfrom(c)
      }else{
        setsstarter(false)
      }
     document.body.style.cursor = hovered ? "pointer" : "auto"
     return () => clearInterval(intervalId);
   }, [hovered,props.clicked,counter])
    const material = new THREE.MeshPhongMaterial({
      color: colorfrom,    // red (can also use a CSS color string here)
      flatShading: true,

    });



    const styles = useSpring({
      loop: true,
      from: { rotation: [.2, .1, .2 ],
               position: [0,2,0],
             },
      to: { rotation: [-.1, -.1, .1],
            position: [0,-1,0]
            },
      config:{ mass: 100, tension: 100, friction: 0 }
    });

    const styles2 = useSpring({
      loop: true,
      from: { rotation: [.2, -.4, .2 ],
               position: [0,3,0],
             },
      to: { rotation: [-.1, .4, .1],
            position: [0,0,0]
            },
      config:{ mass: 100, tension: 100, friction: 0 }
    });





  return (

    <group castShadow receiveShadow ref={group} {...props} dispose={null}
    onPointerOver={(e) => (e.stopPropagation(), sethovered(true))}
    onPointerOut={(e) => (e.stopPropagation(), sethovered(false))}
    >
      <group rotation={[0, -Math.PI / 4, 0]} >
        <group rotation={[0, 0, 0]} >
          <animated.mesh castShadow receiveShadow { ...styles}>
          <group position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0.88]} scale={[scale, scale, scale]} >
            <mesh castShadow receiveShadow
              geometry={nodes.keyboard_1_Material001_0.geometry}
              material={material}
            />
            <mesh
              geometry={nodes.keyboard_1_Material006_0.geometry}
              material={nodes.keyboard_1_Material006_0.material}
            />
            <mesh
              geometry={nodes.keyboard_1_Material007_0.geometry}
              material={nodes.keyboard_1_Material007_0.material}
            />
            <mesh
              geometry={nodes.keyboard_1_Material002_0.geometry}
              material={nodes.keyboard_1_Material002_0.material}
            />
            <mesh
              geometry={nodes.keyboard_1_Material004_0.geometry}
              material={nodes.keyboard_1_Material004_0.material}
            />
          </group>
          </animated.mesh>
          <animated.mesh castShadow receiveShadow { ...styles2}>
          <group position={[5,20,-5]} rotation={[-Math.PI / 2, 0, 0.88]} scale={[scale, scale, scale]}>

            <mesh castShadow receiveShadow geometry={nodes.monitor_1_Material001_0.geometry} material={material} />
            <mesh castShadow receiveShadow geometry={nodes.monitor_1_Material006_0.geometry} material={material} />
            <mesh   geometry={nodes.monitor_1_Material009_0.geometry} material={materials['Material.009']} />
            <mesh   geometry={nodes.monitor_1_Material002_0.geometry} material={nodes.monitor_1_Material002_0.material} />
            <mesh   geometry={nodes.monitor_1_Material004_0.geometry} material={nodes.monitor_1_Material004_0.material} />
            <mesh   geometry={nodes.monitor_1_Material007_0.geometry} material={nodes.monitor_1_Material007_0.material} />
          </group>
          </animated.mesh>


        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/scene.gltf')
