import { useSpring, animated, config } from 'react-spring'






export default function Loading_screen({visible_flag}){

  const spinner = useSpring({

    loop: true,
    from: { rotateZ:180+45, x:-150/2 },
    to: { rotateZ:-180+45 },
    reset: true,
    config: config.molasses,
  })
  const opa = visible_flag ? 1 : 0
return(
  <div style={{width:'100vw',height:'100vh', opacity:opa } }>
    <animated.h1 style={{position:'absolute', top:'5%', width:'100vw', textAlign: 'center',  color:'white'}}>
      <p>LOADING...</p>
      <p>Dandepaz.net</p>
    </animated.h1>
    <animated.div style={{
      position:'relative',
      width:'150px',
      height:'150px',
      left: '50%',
      top:'32%',
      borderRadius: '15px',
      backgroundColor:'#C2B280',
      ...spinner,
      }}/>


  </div >
)
}
