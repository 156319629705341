
import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'
import { useSpring, animated, config } from 'react-spring'
import React, { useEffect, useState } from 'react'

const useCheckMobileScreen = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {setWidth(window.innerWidth);}
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {window.removeEventListener('resize', handleWindowSizeChange);}
  }, []);
  // console.log(width)
  return (width < 720);
}

export function Landing_ui(){
  const styles1 = useSpring({
    loop: true,
    from: { rotateZ:-1, y:1, },
    to: { rotateZ:0,  y:-1, },
    delay: 0,
    config:{ mass: 10, tension: 10, friction: 0 }
  });
  const styles2 = useSpring({
    loop: true,
    from: { rotateZ:-1, y:1, },
    to: { rotateZ:1,  y:-1, },
    delay: 100,
    config:{ mass: 10, tension: 10, friction: 0 }
  });
  const styles3 = useSpring({
    loop: true,
    from: { rotateZ:-1, y:1, },
    to: { rotateZ:0,  y:-1, },
    delay: 200,
    config:{ mass: 10, tension: 10, friction: 0 }
  });
  return(
    <div style={{}} >
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px', color:'#b5dbdc', ...styles1}}>
      {useCheckMobileScreen()? "I'm Dan!" : "I'm Dan, welcome to my Island!"}
    </animated.h3>
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px', color:'#b5dbdc',...styles2}}>
    {useCheckMobileScreen()? "A Software Dev" : "This webite is a tech-demo / hub for my info"}
    </animated.h3>
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px', color:'#b5dbdc',...styles2}}>
    {useCheckMobileScreen()? "" : "Hope you enjoy it!"}
    </animated.h3>
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '10px', color:'#b5dbdc',...styles3}}>
    {useCheckMobileScreen()? "*This place is bettter on PC*" : "Click on the floaties!"}
    </animated.h3>


    </div>
  )
}

function Get_note(music_state,delay){
  const stylesL = useSpring({
    pause:!music_state, loop: true,
    from: { rotateZ:-10, y:20, },
    to: { rotateZ:10,  y:-10, },
    delay: delay,
    config:{ mass: 10, tension: 100, friction: 0 }
  });
  return (<animated.p style={{position:'relative', top: 0, left:0, ...stylesL}}>
            ♫
          </animated.p>)
}

export function Boombox_ui({music_state}){

  let dealy = 0
  let inc = 200

  let NoteList=()=>{
    let noteList=[];
    for (var i = 0; i < 5; i++) {

      noteList.push(Get_note(music_state,dealy))
      dealy +=inc
    }
    return noteList
  }





  return(
    <div>
    <h3 style={{ textAlign: 'center', position:'relative', top: '0px', color:'#b5dbdc'}}>
      <p>
      Play some music!
      </p>
      <p>
      {music_state ? 'Audio is on ♫':'Audio is off' }
      </p>
      <div style={{display:'flex',flexDirection:'row',alignItems:'center',alignContent:"center",justifyContent:'space-around'}}>
      <NoteList/>
      </div>
      <h5 style={{top: '20px'}}>
      Click Boombox to toggle
      </h5>

    </h3>

    </div>
  )
}
export function Car_ui(){
  const styles1 = useSpring({
    loop: true,
    from: { rotateZ:0, y:15, },
    to: { rotateZ:0,  y:15, },
    delay: 0,
    config:{ mass: 30, tension: 100, friction: 0 }
  });

  return(
    <>
    <div >
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-40px', color:'#b5dbdc', ...styles1}}>
      This website was created from the ground up in a month.
    </animated.h3>
    </div>
    <div>
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-40px', color:'#b5dbdc',...styles1}}>
      Skills I needed to learn to complete it:
    </animated.h3>
    </div>
    <div >
    <animated.h4 style={{ textAlign: 'center', position:'relative', top: '-40px', color:'#b5dbdc',...styles1}}>
      HTML,CSS,JS,REACT,THREE.JS,GLSL,Blender
    </animated.h4>
    </div>
    <div >
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-40px',  color:'#b5dbdc',...styles1}}>

       <a href="https://github.com/adeadzeplin" style={{ color:'#b5dbdc'}} target="_blank">Github portfolio</a>

    </animated.h3>
    </div>
    </>
  )
}
export function Free_cam_ui(){
  const styles1 = useSpring({
    loop: true,
    from: { rotateZ:0, y:15, },
    to: { rotateZ:0,  y:15, },
    delay: 0,
    config:{ mass: 30, tension: 100, friction: 0 }
  });
  return(
    <div>
    <animated.h2 style={{ textAlign: 'center', position:'relative', top: '-40px', color:'#b5dbdc', ...styles1}}>
      Free Camera Mode!
    </animated.h2>
    <animated.h4 style={{ textAlign: 'center', position:'relative', top: '20px', color:'#b5dbdc', ...styles1}} >
    Click and Drag to rotate
    </animated.h4>
    <animated.h4 style={{ textAlign: 'center', position:'relative', top: '20px', color:'#b5dbdc', ...styles1}} >
    {useCheckMobileScreen()?"Pinch":"Scroll"} to zoom
    </animated.h4>
    </div>


  )
}
export function Computer_ui(){
  const styles1 = useSpring({
    loop: true,
    from: { rotateZ:0, y:15, },
    to: { rotateZ:0,  y:15, },
    delay: 0,
    config:{ mass: 30, tension: 100, friction: 0 }
  });

  return(
    <>
    <div >
    <animated.h2 style={{ textAlign: 'center', position:'relative', top: '-40px', color:'#b5dbdc', ...styles1}}>
      Software, Hardware, you name it:)
    </animated.h2>
    </div>
    <div>
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-40px', color:'#b5dbdc',...styles1}}>
      Embedded Systems, IOT, Software Applications, and Funky Bots
    </animated.h3>
    </div>
    <div >
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-40px', color:'#b5dbdc',...styles1}}>
      If it's silly I'll enjoy it
    </animated.h3>
    </div>
    <div >
    <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-30px',  color:'#b5dbdc',...styles1}}>
      Bachelor's in Computer Engineering

    </animated.h3>
    </div>
    </>
  )
}
export function Day_controll_ui({...props}){
  const styles1 = useSpring({
    loop: true,
    from: { rotateZ:0, y:15, },
    to: { rotateZ:0,  y:25, },
    delay: 0,
    config:{ mass: 30, tension: 100, friction: 0 }
  });

  return(
    <>
    <div>
    <animated.h1 style={{ textAlign: 'center', position:'relative', top: '0px', color:'#b5dbdc', ...styles1}}>
      Daytime Toggle
    </animated.h1>


    <animated.p style={{ textAlign: 'center', position:'relative', top: '100px', color:'#b5dbdc', ...styles1}}>
    {useCheckMobileScreen() ?<div/>  :  <div>try switching while the music is playing :)</div>}
    </animated.p>

    </div>
    </>
  )
}

export function Social_ui({...props}){
  const styles1 = useSpring({
    loop: true,
    from: { rotateZ:0, y:0, },
    to: { rotateZ:0,  y:15, },
    delay: 0,
    config:{ mass: 30, tension: 100, friction: 0 }
  });
  const styles2 = useSpring({
    loop: true,
    from: { rotateZ:0, y:15, },
    to: { rotateZ:0,  y:0, },
    delay: 0,
    config:{ mass: 30, tension: 100, friction: 0 }
  });
    return(
      <>
      <div>
        <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'-60px', color:'#b5dbdc', ...styles1}}>
          <a href="https://www.linkedin.com/in/dansdp/" style={{ color:'#b5dbdc'}} target="_blank">Linkedin</a>
        </animated.h3>
      </div>
      <div>
        <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'60px', color:'#b5dbdc',...styles2}}>
          <a href="https://github.com/adeadzeplin" style={{ color:'#b5dbdc'}} target="_blank">Github</a>
        </animated.h3>
      </div>
      <div >
        <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'-60px', color:'#b5dbdc',...styles1}}>
          <a href="https://twitter.com/adeadzeplin" style={{ color:'#b5dbdc'}} target="_blank">Twitter</a>
        </animated.h3>
      </div>
      <div >
        <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'60px',  color:'#b5dbdc',...styles2}}>
           <a href="mailto:dan.smith.depaz@gmail.com " style={{ color:'#b5dbdc'}} target="_blank">Email</a>
        </animated.h3>
      </div>
      </>
    )
  }
  export function Credits_ui({...props}){
    const styles1 = useSpring({
      loop: true,
      from: { rotateZ:0, x:-25, },
      to: { rotateZ:0,  x:35, },
      delay: 0,
      config:{ mass: 50, tension: 80, friction: 0 }
    });
    const styles2 = useSpring({
      loop: true,
      from: { rotateZ:0, x:35, },
      to: { rotateZ:0,  x:-50, },
      delay: 0,
      config:{ mass: 50, tension: 90, friction: 0 }
    });
    const styles3 = useSpring({
      loop: true,
      from: { rotateZ:0, x:35, },
      to: { rotateZ:0,  x:-50, },
      delay: 0,
      config:{ mass: 50, tension: 70, friction: 0 }
    });

      return(
        <>
        <div>
          <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'-60px', color:'#b5dbdc', ...styles1}}>
          <a href="https://soundcloud.com/officialdionysus/dans-tropical-adventure-night" style={{ color:'#b5dbdc'}} target="_blank">Night Music</a>

          </animated.h3>
        </div>
        <div>
          <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'60px', color:'#b5dbdc',...styles2}}>
          <a href="https://soundcloud.com/officialdionysus/dans-tropical-adventure-day" style={{ color:'#b5dbdc'}} target="_blank">Day Music</a>

          </animated.h3>
        </div>
        <div >
          <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'-60px', color:'#b5dbdc',...styles3}}>
            <a href="https://sketchfab.com/features/gltf" style={{ color:'#b5dbdc'}} target="_blank">Site for models</a>
          </animated.h3>
        </div>
        <div >
          <animated.h3 style={{ textAlign: 'center', position:'relative', top: '-10px',left:'0px',  color:'#b5dbdc',}}>
            Music by 'officialdionysus' on soundcloud
          </animated.h3>
        </div>
        </>
      )
    }
