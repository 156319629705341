import { Canvas,extend, useLoader, useFrame, useThree,} from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useSpring, useTransition, animated,config } from '@react-spring/three'
import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'

import * as THREE from 'three'
import { OrbitControls, Sky, Cloud, Stars, useHelper, Preload, shaderMaterial  } from '@react-three/drei'
import { Geometry,Water } from "three-stdlib";
import Button from '@mui/material/Button';
import Wave from 'react-wavify';
import { createTheme, ThemeProvider } from '@mui/material/styles';


import Make_spotlight from './spot_light'
import Loading_screen from './loading_screen'
import Zeppelin from './Zeppelin'
import {FancyOcean,CheapOcean} from './ocean'

import Clapper from './clapper'
import Boombox from './boombox'
import Computer from './computer'
import Deadzeplin from './Deadzeplin'
import Palmtree from './palmtree'
import Bush from './Bush'
import Umbrella from './umbrella'
import Moai from './Moai'
import {Seagul,Seaguul} from './Seagul'
import Sunmoon from './sunmoon'
import Shark from './Shark'
import Fish from './Fish'
import Camera from './Camera'
import Car from './Car'

import {Boombox_ui,Landing_ui,Computer_ui, Day_controll_ui, Social_ui, Credits_ui, Car_ui,Free_cam_ui} from './clicked_ui'

import {add_2_lists, hextorgb, rgbtohex, rgblisttohex} from './miscelaneous'

const Island = () => {
  var gltf = useLoader(GLTFLoader, './island/Redoneisland.glb')
  var grass_geometry = useLoader(GLTFLoader, './island/island_grass.glb')
  const material = new THREE.MeshPhongMaterial({
    color: '#C2B280',
    flatShading: true,
  });
  const grassmaterial = new THREE.MeshPhongMaterial({
    color: '#42B240',
    flatShading: true,
  });


  return(
    <>
    <mesh
    shadows
    castShadow
    receiveShadow
    geometry={grass_geometry.nodes.Landscape001.geometry}
    scale={70}
    material={grassmaterial}
    position={[0,-32,-250]}
     >
      </mesh>
    <mesh
    shadows
    castShadow
    receiveShadow
    geometry={gltf.nodes.Landscape001.geometry}
    scale={100}
    material={material}
    position={[0,-60,-250]}
     >
      </mesh>

      </>
  )
}

function Dolly({cam_pos=[0,0,0] , Str = .006, setLoader=null}) {
  const strength =Str
  let unit_vec = new THREE.Vector3(0 - cam_pos[0],0,-250 - cam_pos[2])
  unit_vec = unit_vec.normalize()
  const x_offset = unit_vec.x*120
  const z_offset = unit_vec.z*120

  useFrame(({camera}) => {
    camera.position.x += ((cam_pos[0]-x_offset)-camera.position.x)*strength
    camera.position.y += (cam_pos[1]-camera.position.y)*strength
    camera.position.z += ((cam_pos[2]-z_offset)-camera.position.z)*strength
  })

  useEffect(() => { setLoader(false) }, [])//when the site is loaded disable the loading screen

  return null
}

function My_text({text}){

  return(
    <div>
    <h2 style={{position:'relative', top: '50px', textAlign: 'center', color:'#b5dbdc'}}>
      {text}
    </h2>
    </div>
  )
}



function vec3_to_list(vec){
  return([parseInt(vec[0]*255),parseInt(vec[1]*255),parseInt(vec[2]*255)])
}



export default function Main(){
  document.title = 'DandePaz . 🥅'
  const cam = {
    position: [300,2000,-600], fov: 60, near: 1, far: 10000, zoom: 1
  }
  const [camstate, setCamState] = useState(8)
  const [day_night, setDayState] = useState(false)

  const Move_Camstate = (inc) => {
    setCamState(inc+camstate)
      if (camstate < 0){
        setCamState(8)
      }
      if (camstate >8){
        setCamState(0)
      }

    return
}


  const [navhovered, setnavhovered] = useState(false)

  document.body.style.cursor = navhovered ? "pointer" : "auto"

  const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {setWidth(window.innerWidth);}
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {window.removeEventListener('resize', handleWindowSizeChange);}
    }, []);
    // console.log(width)
    return (width <= 800);
  }
  // console.log(useCheckMobileScreen())
  const [LoaderState, setLoader] = useState(true)

  function day_night_controll(){
    return (day_night)
  }


  const useAudio = () => {

    const [audioday] = useState(new Audio('./Dans tropical adventure (day).mp3'));

    const [audionight] = useState(new Audio('./Dans tropical adventure (night).mp3'));

    const [playing, setPlaying] = useState(false);

    const toggle = () => setPlaying(!playing);
    const ismobile = useCheckMobileScreen()
    // hack to not ruin mobile phone experience
    audioday.volume = (day_night_controll()|| ismobile )? 0.4: 0.0
    audionight.volume = (day_night_controll()|| ismobile )? 0.0:0.4

    useEffect(() => {
        playing ? audioday.play() : audioday.pause();
        if (!ismobile){
          playing ? audionight.play() : audionight.pause();
        }
      },
      [playing]
    );

    useEffect(() => {
      audioday.addEventListener('ended', () => setPlaying(false));
      audionight.addEventListener('ended', () => setPlaying(false));

      return () => {
        audioday.removeEventListener('ended', () => setPlaying(false));
        audionight.removeEventListener('ended', () => setPlaying(false));

      };
    }, []);

    return [playing, toggle];
  };
  const control_day = () =>{
    setCamState(4);
    setDayState(!day_night)
    return
  };
  const [playing, toggleAudio] = useAudio();
  const camera_positions = [
    [0,30,-90],
    [130,30,-130],
    [160,30,-250],
    [130,30,-370],
    [0,30,-400],
    [-130,30,-370],
    [-160,30,-250],
    [-110,30,-130],

    [0,30,-90]
  ]
  const def_cam_speed = 0.03 //useCheckMobileScreen() ? 0.03 : 0.03
  const camera_speeds = [
    def_cam_speed,
    def_cam_speed,
    def_cam_speed,
    null,
    def_cam_speed,
    def_cam_speed,
    def_cam_speed,
    def_cam_speed,
    0.006, //useCheckMobileScreen() ? 0.006 : 0.006,
    null
  ]
  const component_labels = [
    'Splash',
    'PC',
    'Music Box',
    'Free-cam Enable',
    'Day/Night',
    'Drift City',
    'Credits',
    'Social',
    'You Shouldnt be able to see this',
  ]
  const pickable_components = [
    <Landing_ui/>,
    <Computer_ui/>,
    <Boombox_ui music_state={playing}/>,
    <Free_cam_ui/>,
    <Day_controll_ui/>,
    <Car_ui />,

    <Credits_ui/>,
    <Social_ui/>,
    <My_text text=''/>,
  ]
  const targ = [0,-5,-250]



  const wat_col = rgblisttohex(vec3_to_list([0.0, 0.4, 0.7]))
  const wat2_col = rgblisttohex(vec3_to_list([0.0, 0.37, 0.65]))
  const foam_col = rgblisttohex(vec3_to_list([0.7125, 0.8609, 0.8648]))




  const theme = createTheme({
    palette: {
      primary: {
        main: (day_night_controll()?  wat2_col:foam_col),
      },
    },
  });



  console.log(camstate)
  return(
    <>

      <div className="font-link" style={{visible:LoaderState, position: "absolute", width: '100vw', backgroundColor:(day_night_controll() ? '#77CEEB':'#012a4a')}}>
        <Loading_screen style={{textAlign: 'center',}} visible_flag={LoaderState}/>
      </div>

      <div className="font-link" style={{opacity:!LoaderState, position: "relative", width: '100%', height: '60%', marginBottom: '-5vh'}}>

      <Canvas colorManagement shadows camera={cam} mode="concurrent">
        <Suspense fallback={null}>

          {Make_spotlight({
            color:0xf0FF8f,
            position:add_2_lists(camera_positions[camstate],[0,100,0]),
            direction:[0,-1,0],
            angle:.4,
            decay:.1,
            penumbra:.9,
            intensity:.1,
            distance:200
          })}
          {Make_spotlight({
            color:0x414000,
            position:add_2_lists(camera_positions[0],[0,50,50]),
            direction:[0,-1,-10],
            angle:20,
            decay:.1,
            penumbra:.1,
            intensity:.1,
            distance:1000
          })}
          {Make_spotlight({
            color:0x610041,
            position:add_2_lists(camera_positions[4],[0,50,-100]),
            direction:[0,-1,10],
            angle:20,
            decay:.1,
            penumbra:.1,
            intensity:10,
            distance:1000
          })}

          {day_night_controll()? <Make_spotlight color= {0x616041}  position= {[0,250,-200]}  direction= {[0,-1,0]}  angle= {20}  decay= {.1}  penumbra= {.1}  intensity= {1}  distance= {1000}/> :<mesh/>}


          <ambientLight intensity={day_night_controll() ?  0.5 : .15} />

          <mesh>
            <mesh onClick={e => setCamState(0)}>
              <Deadzeplin castShadow receiveShadow position={camera_positions[0]} clicked={useCheckMobileScreen()&&(0==camstate)}/>
            </mesh>
            <mesh onClick={e => setCamState(1)}>
              <Computer castShadow receiveShadow position={camera_positions[1]} clicked={useCheckMobileScreen()&&(1==camstate)}/>
            </mesh>
            <mesh onClick={e => setCamState(2)}>
              <Boombox castShadow receiveShadow
              position={camera_positions[2]}
              onClick={toggleAudio}
              pause={!playing}
              clicked={useCheckMobileScreen()&&(2==camstate)}
              />
            </mesh>
            <mesh onClick={e => setCamState(3)}>
              <Camera castShadow receiveShadow position={camera_positions[3]}  clicked={useCheckMobileScreen()&&(3==camstate)}/>
            </mesh>
            <mesh onClick={e=>control_day()}>
              <Sunmoon castShadow receiveShadow position={camera_positions[4]} daynight={day_night_controll()} clicked={useCheckMobileScreen()&&(4==camstate)}/>
            </mesh>
            <mesh onClick={e => setCamState(5)}>
              <Car castShadow receiveShadow position={camera_positions[5]} clicked={useCheckMobileScreen()&&(5==camstate)}  speed={0.2} radius={20}/>
            </mesh>
            <mesh onClick={e => setCamState(6)}>
              <Clapper castShadow receiveShadow position={camera_positions[6]} clicked={useCheckMobileScreen()&&(6==camstate)}/>
            </mesh>
            <mesh onClick={e => setCamState(7)}>
              <Zeppelin castShadow receiveShadow position={camera_positions[7]} clicked={useCheckMobileScreen()&&(7==camstate)}/>
            </mesh>
          </mesh>
          <Dolly cam_pos={add_2_lists(camera_positions[camstate],[0,30,0])}  Str={camera_speeds[camstate]} setLoader={setLoader}/>
          {useCheckMobileScreen() ? <CheapOcean /> : <FancyOcean/> }
          <Stars radius={day_night_controll() ?100000 :  2000} depth={10} count={useCheckMobileScreen() ? 5000 : 50000} factor={useCheckMobileScreen() ? 50 : 10} saturation={0} fade={!useCheckMobileScreen()}/>

          <Island position={[0,0,0]}/>
          <Palmtree position={[50,55,-200]} rotation={[.6, 0, 0]} />
          <Palmtree position={[-50,50,-150]} rotation={[0, 0, 0]} />
          <Palmtree position={[-50,50,-300]} rotation={[.5, 0, 0]} />
          <Bush position={[50,20,-300]} rotation={[0, 0, 0]} />
          <Bush position={[-50,15,-200]} rotation={[0, 0, .2]} />
          <Bush position={[-70,18,-330]} rotation={[0, 0.1, 0]} />
          <Umbrella position={[50,8,-130]} rotation={[-.50, 0, .3]} />
          <Moai position={[50,20,-330]} rotation={[0, -3.14, 0]}/>
          <Seagul position={[0,-30,-200]} rotation={[0, -3.14/2, 0]} speed={0.25} radius={-300} />
          <Seaguul position={[0,-20,-200]} rotation={[0, -3.14/2, 0]} speed={0.2} radius={-200} />
          <Shark position={[0,-100,-200]}/>

        </Suspense>
        <OrbitControls target={targ} enablePan={false} />
      </Canvas>


      </div>
      <div
      onMouseLeave={() => setnavhovered(false)}
      onMouseEnter={() => setnavhovered(true)}
      onClick={e => ((camstate==7||camstate==8) ? Move_Camstate(-camstate) : Move_Camstate(1))}
      style={{opacity:camstate==8 ?0.0:1.0, position:'absolute', top: '10px',right:'10px'}}>
        <ThemeProvider theme={theme}>
        <Button className="font-link" variant="outlined" style={{color:(day_night_controll()?  wat2_col:foam_col)}}>
          {'>> '}{ component_labels[(camstate==7||camstate==8) ? 0 : camstate+1%8]}
        </Button>
        </ThemeProvider>
      </div>
      <div
      onMouseLeave={() => setnavhovered(false)}
      onMouseEnter={() => setnavhovered(true)}
      onClick={e => (camstate==0 ? Move_Camstate(7) : Move_Camstate(-1))}
      style={{opacity:camstate==8?0.0:1.0, position:'absolute', top: '10px', left:'10px',}}>
        <ThemeProvider theme={theme}>
        <Button className="font-link" variant="outlined" style={{color:(day_night_controll()?  wat2_col:foam_col)}}>
         {component_labels[camstate==0 ? 7 : camstate-1%8]}{' <<'}
        </Button >
        </ThemeProvider>
      </div>

      <div style={{position:'relative', width: '100vw', top: '-60px'}}>
      <Wave fill={foam_col} paused={false} options={{
          height: 60,
          amplitude: 20,
          speed: 0.3,
          points: 4
        }}/>
      </div>
      <div style={{position:'relative', width: '100vw', top: '-190px'}}>
      <Wave fill={wat_col} paused={false} options={{
          height: 60,
          amplitude: 20,
          speed: 0.3,
          points: 4
        }}/>
      </div>
      <div style={{position:'relative', width: '100vw', top: '-320px'}}>
      <Wave fill={wat2_col} paused={false} options={{
          height: 60,
          amplitude: 20,
          speed: 0.3,
          points: 4
        }}/>
      </div>


        <div className="font-link" style={{position:'relative', height: '45vh',top: '-380px', backgroundColor:wat2_col }}>
            {pickable_components[camstate]}
        </div>


    </>
  )


}
