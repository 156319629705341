/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: christyalexia (https://sketchfab.com/christyalexia)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/3d-sketchbook-12-boom-box-8e1627c5b589420c9bbed0e0cfef1502
title: 3D Sketchbook 12 - Boom Box
*/
import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useSpring, useTransition, animated,config } from '@react-spring/three'

import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'









export default function Boombox({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('./boombox/scene.gltf')








  const [hovered, sethovered] = useState(false)
  const [starter, setsstarter] = useState(false)
  const [colorfrom, setcolorfrom] = useState('#000000')
  const [counter, setcounter] = useState()
  const zep_col_dark = '#4D0923'
  const zep_col_light = '#8D0943'
  const [colorto, setto] = useState('#000000')
  useEffect(() => {

    let intervalId;
    let perc = 100;
    if(props.clicked){
      sethovered(true)
    }
    if((hovered)&&(starter)){
      setcounter(perc)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if (hovered && (starter==false)){
      setsstarter(true)
      setcounter(0)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if(starter){
      intervalId = setInterval(() => {
        setcounter(counter + 1);
      }, 1)
    }
    if (counter < perc){
      var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
      var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
      var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
      var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
      setcolorfrom(c)
    }else{
      setsstarter(false)
    }
   document.body.style.cursor = hovered ? "pointer" : "auto"
   return () => clearInterval(intervalId);
 }, [hovered,props.clicked,counter])
  const material = new THREE.MeshPhongMaterial({
    color: colorfrom,    // red (can also use a CSS color string here)
    flatShading: true,
  });

  const styles = useSpring({
    pause:props.pause,
    loop: true,
    from: { rotation: [.2, 0, .1 ],
             position: add_2_lists(props.position,[0,2,-10]),
           },
    to: { rotation: [-.2, 0, -.1],
          position: add_2_lists(props.position,[0,-2,-10])
          },
    config:{ mass: 10, tension: 100, friction: 0 }
  });
  const flapper_style = useSpring({
    pause:props.pause,
    loop: true,
    from: { rotation: [.36, 0, 0 ],
           },
    to: { rotation: [0, 0, 0],},
    config:{ mass: 10, tension: 100, friction: 0 }
  });


  return (
    <animated.mesh castShadow receiveShadow { ...styles}>

    <group ref={group} scale={10} rotation={[0, Math.PI / 2, 0]} {...props} dispose={null}
    position={[-10,0,10]}
    onPointerOver={(e) => (e.stopPropagation(), sethovered(true))}
    onPointerOut={(e) => (e.stopPropagation(), sethovered(false))}

    >
      <group rotation={[-Math.PI / 2, 0, 0]} >
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, 0.65, 0]} scale={[1, 0.63, 0.38]}>
            <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={material} />
            <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={nodes.Object_5.material} />
            <mesh castShadow receiveShadow geometry={nodes.Object_6.geometry} material={materials.glass} />
          </group>
          <group position={[0.64, 0.42, 0.38]} scale={[0.29, 0.29, 0.07]}>
            <mesh  geometry={nodes.Object_8.geometry} material={nodes.Object_8.material} />
          </group>
          <group position={[-0.66, 0.42, 0.38]} scale={[0.29, 0.29, 0.07]}>
            <mesh geometry={nodes.Object_10.geometry} material={nodes.Object_10.material} />
          </group>
          <group position={[0.12, 0.69, 0.34]} scale={[0.04, 0.04, 0.01]}>
            <mesh geometry={nodes.Object_12.geometry} material={nodes.Object_12.material} />
          </group>
          <group position={[-0.11, 0.69, 0.34]} scale={[0.04, 0.04, 0.01]}>
            <mesh geometry={nodes.Object_14.geometry} material={nodes.Object_14.material} />
          </group>
          <group position={[-0.66, 1.27, 0.18]} scale={0.06}>
            <mesh geometry={nodes.Object_16.geometry} material={material} />
          </group>
          <group position={[-0.47, 1.27, 0.18]} scale={0.06}>
            <mesh geometry={nodes.Object_18.geometry} material={material} />
          </group>
          <group position={[-0.26, 1.27, 0.18]} scale={0.06}>
            <mesh geometry={nodes.Object_20.geometry} material={material} />
          </group>
          <group position={[1.03, 0.92, 0.2]} rotation={[0, 0, 1.57]} scale={[0.1, 0.03, 0.1]}>
            <mesh geometry={nodes.Object_22.geometry} material={material} />
          </group>
          <group position={[0, 1.73, 0]} scale={[1.14, 0.08, 0.08]}>
            <animated.mesh castShadow receiveShadow geometry={nodes.Object_24.geometry} {...flapper_style} material={material} />
          </group>
          <group position={[1, 1.2, 0]} rotation={[0, 0, 1.57]} scale={[0.04, 0.01, 0.04]}>
            <mesh {...flapper_style} geometry={nodes.Object_26.geometry} material={nodes.Object_26.material} />
          </group>
          <group position={[-1.01, 1.2, 0]} rotation={[0, 0, 1.57]} scale={[0.04, 0.01, 0.04]}>
            <mesh geometry={nodes.Object_28.geometry} material={nodes.Object_28.material} />
          </group>
        </group>
      </group>
    </group>
    </animated.mesh>
  )
}

useGLTF.preload('./boombox/scene.gltf')
