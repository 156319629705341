/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Moai({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('./moai_low_poly/scene.glb')
  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[-Math.PI / 2, 0, 0]}>
            <group position={[0, 0, 30.19]}>
              <mesh
                geometry={nodes['moai_Material_#35_0'].geometry}
                material={materials.Material_35}
                position={[0, 0, -28.98]}
                scale={0.4}
              />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/scene.glb')
