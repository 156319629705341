export function add_2_lists(lista,listb){
  return [lista[0]+listb[0],lista[1]+listb[1],lista[2]+listb[2]]
}
export function hextorgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}
export function componentToHex(c) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
export function rgbtohex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
export function rgblisttohex(lis) {
  return "#" + componentToHex(lis[0]) + componentToHex(lis[1]) + componentToHex(lis[2]);
}
