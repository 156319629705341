/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Quince Creative (https://sketchfab.com/quincemedia)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/film-clapperboard-free-3d-model-c5c798aa23024b868f7653229911b915
title: Film Clapperboard Free 3D Model
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useSpring, useTransition, animated,config } from '@react-spring/three'

import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'




export default function Clapper({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('./clapper/scene.gltf')



  const [hovered, sethovered] = useState(false)
  const [starter, setsstarter] = useState(false)
  const [colorfrom, setcolorfrom] = useState('#000000')
  const [counter, setcounter] = useState()
  const zep_col_dark = '#204e20'
  const zep_col_light = '#20fe20'
  const [colorto, setto] = useState('#000000')
  useEffect(() => {

    let intervalId;
    let perc = 100;
    if(props.clicked){
      sethovered(true)
    }
    if((hovered)&&(starter)){
      setcounter(perc)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if (hovered && (starter==false)){
      setsstarter(true)
      setcounter(0)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if(starter){
      intervalId = setInterval(() => {
        setcounter(counter + 1);
      }, 1)
    }
    if (counter < perc){
      var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
      var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
      var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
      var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
      setcolorfrom(c)
    }else{
      setsstarter(false)
    }
   document.body.style.cursor = hovered ? "pointer" : "auto"
   return () => clearInterval(intervalId);
 }, [hovered,props.clicked,counter])
  const material = new THREE.MeshPhongMaterial({
    color: colorfrom,    // red (can also use a CSS color string here)
    flatShading: true,

  });




  const styles = useSpring({
    loop: false,
    from: { rotation: [.2, -.1, .2 ],
             position: add_2_lists(props.position,[0,2,0]),
           },
    to: { rotation: [-.1, .1, .1],
          position: add_2_lists(props.position,[0,-1,0])
          },
    config:{ mass: 100, tension: 100, friction: 0 }
  });
  const flapper_style = useSpring({
    loop: false,
    from: { rotation: [.26, 0, 0 ],
           },
    to: { rotation: [0, 0, 0],},
    config:{ mass: 10, tension: 100, friction: 0 }
  });




  return (
    <animated.mesh castShadow receiveShadow { ...styles}>
    <group ref={group} {...props} dispose={null}
    onPointerOver={(e) => (e.stopPropagation(), sethovered(true))}
    onPointerOut={(e) => (e.stopPropagation(), sethovered(false))}
    >
      <group rotation={[-Math.PI / 2, 0, 0]} position={[160,-35,250]}>
        <group position={[0, 0, 14.25]}>
          <mesh castShadow receiveShadow geometry={nodes.Cube_0.geometry} material={material} />
        </group>
        <group position={[0, 0, 13.71]}>
          <mesh castShadow receiveShadow geometry={nodes.Cube002_0.geometry} material={material} />
          <mesh castShadow receiveShadow geometry={nodes.Cube002_1.geometry} material={material} />
        </group>
        <group position={[0, -9.01, 15.44]}>
          <mesh geometry={nodes.Cube003_0.geometry} material={material} />
        </group>
        <group position={[-1.68, -9.01, 15.44]}>
          <mesh geometry={nodes.Cube004_0.geometry} material={material} />
        </group>
        <group position={[0.99, -9.49, 16.26]} rotation={[1.79, 0, -Math.PI / 2]} scale={[0.21, 0.21, 0.21]}>
          <mesh geometry={nodes.Sphere04_0.geometry} material={material} />
        </group>
        <group position={[0.99, -9.49, 14.02]} rotation={[0.83, 0, -Math.PI / 2]} scale={[0.21, 0.21, 0.21]}>
          <mesh geometry={nodes.Sphere04001_0.geometry} material={material} />
        </group>
        <group position={[0.99, -6.42, 14.02]} rotation={[2.37, 0, -Math.PI / 2]} scale={[0.21, 0.21, 0.21]}>
          <mesh geometry={nodes.Sphere04002_0.geometry} material={material} />
        </group>
        <group position={[-0.99, -6.42, 14.02]} rotation={[2.37, 0, Math.PI / 2]} scale={[-0.21, 0.21, 0.21]}>
          <mesh geometry={nodes.Sphere04003_0.geometry} material={material} />
        </group>
        <group position={[-0.99, -9.49, 14.02]} rotation={[0.83, 0, Math.PI / 2]} scale={[-0.21, 0.21, 0.21]}>
          <mesh geometry={nodes.Sphere04004_0.geometry} material={material} />
        </group>
        <group position={[-0.99, -9.49, 16.26]} rotation={[1.79, 0, Math.PI / 2]} scale={[-0.21, 0.21, 0.21]}>
          <mesh geometry={nodes.Sphere04005_0.geometry} material={material} />
        </group>
        <group position={[0.96, -9.5, 16.28]} rotation={[0.29, 0, 0]}>
          <animated.mesh castShadow receiveShadow geometry={nodes.Cube001_0.geometry} {...flapper_style} material={material} />
        </group>
        <group position={[38.23, -8.13, 7.88]} rotation={[1.77, 1.27, -1.7]} />
      </group>
    </group>
    </animated.mesh>
  )
}
useGLTF.preload('./clapper/scene.gltf')
