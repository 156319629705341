/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: AlienDev (https://sketchfab.com/AlienDev)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/models/58eddd6fbc2448c38efd1e3df3d0f342
title: Low Poly Shark
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useSpring, useTransition, animated,config } from '@react-spring/three'

import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'

export default function Shark({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('./shark/scene.gltf')


  const styles = useSpring({
    loop: true,
    from: { rotation: [.1, 0, .1 ],
             position: [0,2,-400],
           },
    to: { rotation: [0, 0, -.1],
          position: [0,-2,-420],
          },
    config:{ mass: 10, tension: 100, friction: 0 }
  });
  const styles2 = useSpring({
    loop: true,
    from: {rotation: [0, -3.14*2, 0],
           },
    to: {rotation: [0, 0, -0],
          },
    config:{ mass: 10, tension: 10, friction: 100 }
  });



  return (
    <animated.mesh { ...styles2}>
    <animated.mesh { ...styles}>
      <group ref={group} {...props} dispose={null} scale={100}>
        <group rotation={[-Math.PI / 2, 0, -Math.PI / 2]} >
          <mesh geometry={nodes.mesh_0.geometry} material={materials.Texture} />
        </group>
      </group>
      </animated.mesh>
      </animated.mesh>
  )
}
