/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useSpring, animated } from '@react-spring/three'

import { hextorgb, rgbtohex} from './miscelaneous'

export default function Deadzeplin({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF('/deadzeplin.glb')



    const [hovered, sethovered] = useState(false)
    const [starter, setsstarter] = useState(false)
    const [colorfrom, setcolorfrom] = useState('#000000')
    const [counter, setcounter] = useState()
    const zep_col_dark = '#2139fB'
    const zep_col_light = '#6179ff'
    const [colorto, setto] = useState('#000000')
    useEffect(() => {

      let intervalId;
      let perc = 100;
      if(props.clicked){
        sethovered(true)
      }
      if((hovered)&&(starter)){
        setcounter(perc)
        setto(zep_col_dark)
        setcolorfrom(zep_col_light)
      }
      if (hovered && (starter===false)){
        setsstarter(true)
        setcounter(0)
        setto(zep_col_dark)
        setcolorfrom(zep_col_light)
      }
      if(starter){
        intervalId = setInterval(() => {
          setcounter(counter + 1);
        }, 1)
      }
      if (counter < perc){
        var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
        var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
        var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
        var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
        setcolorfrom(c)
      }else{
        setsstarter(false)
      }
     document.body.style.cursor = hovered ? "pointer" : "auto"
     return () => clearInterval(intervalId);
   },[starter,colorfrom,colorto,hovered,props.clicked,counter])

    const material = new THREE.MeshPhongMaterial({
      color: colorfrom,
      flatShading: true,
    });

    const styles = useSpring({
      pause:props.pause,
      loop: true,
      from: { rotation: [-.01, 0,0 ],
              position: [0,0,0],
             },
      to: { rotation: [.01, 0, 0],
        position: [0,0,0],
            },
      config:{ mass: 10, tension: 100, friction: 0 }
    });
    const styles2 = useSpring({
      pause:props.pause,
      loop: true,
      from: { rotation: [.1, 0,0 ],
              position: [0,2,0],
             },
      to: { rotation: [0, 0, 0],
        position: [0,0,0],
            },
      config:{ mass: 10, tension: 50, friction: 0 }
    });
    const styles3 = useSpring({
      pause:props.pause,
      loop: true,
      from: { rotation: [0, .3,0 ],
              position: [2,10,0],
             },
      to: { rotation: [0, 0, 0],
        position: [0,10,0],
            },
      config:{ mass: 9, tension: 50, friction: 0 },

    });

    let hover_over = (e) => {
      e.stopPropagation()
      sethovered(true)
    }
    let hover_off = (e) => {
      e.stopPropagation()
      sethovered(false)
    }
  return (

    <group ref={group} {...props} dispose={null}
    onPointerOver={(e) => hover_over(e)}
    onPointerOut={(e) => hover_off(e)}
    scale={2}

    >
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]} position={[0,-0,-10]}>
        <animated.mesh castShadow receiveShadow { ...styles3}>
        <animated.mesh castShadow receiveShadow { ...styles2}>
          <mesh castShadow receiveShadow geometry={nodes.Mesh_0_1.geometry} material={material} />
          <animated.mesh castShadow receiveShadow { ...styles}>
            <mesh castShadow receiveShadow geometry={nodes.Mesh_0_2.geometry} material={material} />
          </animated.mesh>
        </animated.mesh>
        </animated.mesh>

        </group>
      </group>
    </group>

  )
}

useGLTF.preload('/deadzeplin.glb')
