import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import * as THREE from 'three'
import {SpotLightHelper} from "three"




interface Test_spotlight_props {
    color?: int;
    position?: list;
    direction?: list;
    angle?: float;
    intensity?: float;
    distance?: float;
    decay?: float;

}
export default function Make_spotlight({color=0xFFFFFF,position=[0,0,0],direction=[0,0,0],angle=1,intensity=1,distance=0,penumbra=0,decay=0}:Test_spotlight_props ){
  const look_at = [position[0]+direction[0],position[1]+direction[1],position[2]+direction[2]]
  const light = useMemo(() => new THREE.SpotLight(color), [])
  light.angle = angle
  light.distance = distance
  light.penumbra = penumbra
  light.decay = decay
  light.castShadow = true;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;
  return(
    <group castShadow     shadowMap
        castShadow
        receiveShadow>
    <primitive castShadow     shadowMap
        castShadow
        receiveShadow object={light} position={position} />
    <primitive castShadow     shadowMap
        castShadow
        receiveShadow object={light.target} position={look_at}  />
    </group>
  )
}
