/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'


export default function Bush({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF('./island/bush.glb')


  const material = new THREE.MeshPhongMaterial({
    color: '#42B240',
    flatShading: true,
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.2}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group rotation={[-Math.PI / 2, 0, 0]}>
            <group position={[-0.44, -35.32, 27.89]}>
              <mesh castShadow receiveShadow
                geometry={nodes.leaves_tropical_plant_0.geometry}
                material={material}
              />
            </group>
          </group>
          <group rotation={[-Math.PI / 2, 0, 0]}>
            <group position={[3.54, 0.19, 0.18]}>
              <mesh castShadow receiveShadow geometry={nodes.trunk_tropical_plant_0.geometry} material={material} />
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/bush.glb')
