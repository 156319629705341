/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: simonaskLDE (https://sketchfab.com/simonaskLDE)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/low-poly-seagull-with-animation-rigged-985024328902444c8270c0f09acc897e
title: Low-Poly Seagull (with Animation & Rigged)
*/

import { useGLTF, useAnimations } from '@react-three/drei'
import React, { useRef, useState, useEffect } from 'react'
import * as THREE from 'three'
import { useSpring, useTransition, animated,config } from '@react-spring/three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { useFrame, useLoader} from '@react-three/fiber'
import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'



export function Seagul({ ...props }) {

  const { nodes, materials, animations } = useLoader(GLTFLoader,'./seagull/scene.gltf')
  // const { actions } = useAnimations(animations, group)
  // useEffect(() =>  void actions.ArmatureAction.play(), [actions])
  const group = useRef()
  const mesh = useRef()
  const [start] = useState(() => Math.random() * 5000)
  const [mixer] = useState(() => new THREE.AnimationMixer())
  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])


  useFrame((state, delta) => {
    const elap = state.clock.getElapsedTime()*props.speed+start;
    group.current.rotation.y = elap-3.14/2;
    group.current.position.x = Math.sin(elap)*props.radius;
    group.current.position.z = Math.cos(elap)*props.radius;

    mixer.update(delta*props.speed*2)
  });
  // console.log(mixer)
  const up_n_down = useSpring({
    loop: true,

    from: { rotation: [-.01, 0,0 ],
            position: [0,170,-250],
           },
    to: { rotation: [.01, 0, 0],
      position: [0,170,-250],
          },
    config:{ mass: 10, tension: 100, friction: 0 }
  });




  return (

    <mesh>
    <animated.mesh {...up_n_down}>
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.02, 0, -2]} rotation={[-Math.PI / 2, 0, -Math.PI]}>
        <group  rotation={[Math.PI / 2, 0, 0]}>
          <group  name="Armature_7" position={[0, 0.55, -1.2]} rotation={[-Math.PI / 2, 0, 0]}>
            <primitive object={nodes.GLTF_created_0_rootJoint} />

            <skinnedMesh castShadow
              geometry={nodes.Object_7.geometry}
              material={materials.Seagull_Base_Material}
              skeleton={nodes.Object_7.skeleton}
            />
            <skinnedMesh castShadow
              geometry={nodes.Object_8.geometry}
              material={materials.Seagul_DarkEyeTail}
              skeleton={nodes.Object_8.skeleton}
            />
            <skinnedMesh castShadow
              geometry={nodes.Object_9.geometry}
              material={materials.Seagull_Yellow}
              skeleton={nodes.Object_9.skeleton}
            />
            <skinnedMesh castShadow
              geometry={nodes.Object_10.geometry}
              material={materials.Seagull_Red}
              skeleton={nodes.Object_10.skeleton}
            />
          </group>
        </group>
      </group>
    </group>

    </animated.mesh>
    </mesh>
  )
}

export function Seaguul({ ...props }) {

  const { nodes, materials, animations } = useLoader(GLTFLoader,'./seagull/scene.glb')
  // const { actions } = useAnimations(animations, group)
  // useEffect(() =>  void actions.ArmatureAction.play(), [actions])
  const group = useRef()
  const mesh = useRef()
  const [start] = useState(() => Math.random() * 5000)
  const [mixer] = useState(() => new THREE.AnimationMixer())
  useEffect(() => void mixer.clipAction(animations[0], group.current).play(), [])


  useFrame((state, delta) => {
    const elap = state.clock.getElapsedTime()*props.speed+start;
    group.current.rotation.y = elap-3.14/2;
    group.current.position.x = Math.sin(elap)*props.radius;
    group.current.position.z = Math.cos(elap)*props.radius;

     mixer.update(delta*props.speed*2)
  });
  // console.log(mixer)
  const up_n_down = useSpring({
    loop: true,

    from: { rotation: [-.01, 0,0 ],
            position: [0,170,-250],
           },
    to: { rotation: [.01, 0, 0],
      position: [0,170,-250],
          },
    config:{ mass: 10, tension: 100, friction: 0 }
  });




  return (

    <mesh>
    <animated.mesh {...up_n_down}>
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.02, 0, -2]} rotation={[-Math.PI / 2, 0, -Math.PI]}>
        <group  rotation={[Math.PI / 2, 0, 0]}>
          <group  name="Armature_7" position={[0, 0.55, -1.2]} rotation={[-Math.PI / 2, 0, 0]}>
            <primitive object={nodes.GLTF_created_0_rootJoint} />

            <skinnedMesh castShadow
              geometry={nodes.Object_7.geometry}
              material={materials.Seagull_Base_Material}
              skeleton={nodes.Object_7.skeleton}
            />
            <skinnedMesh castShadow
              geometry={nodes.Object_8.geometry}
              material={materials.Seagul_DarkEyeTail}
              skeleton={nodes.Object_8.skeleton}
            />
            <skinnedMesh castShadow
              geometry={nodes.Object_9.geometry}
              material={materials.Seagull_Yellow}
              skeleton={nodes.Object_9.skeleton}
            />
            <skinnedMesh castShadow
              geometry={nodes.Object_10.geometry}
              material={materials.Seagull_Red}
              skeleton={nodes.Object_10.skeleton}
            />
          </group>
        </group>
      </group>
    </group>

    </animated.mesh>
    </mesh>
  )
}
