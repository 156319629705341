import { Canvas,extend, useLoader, useFrame, useThree,} from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useSpring, useTransition, animated,config } from '@react-spring/three'
import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import * as THREE from 'three'
import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'



export default function Zeppelin(props) {
  var gltf = useLoader(GLTFLoader, './zeppelin/scene.gltf')
  var textur = useLoader(GLTFLoader, './zeppelin/texture/scene.gltf')
  const zep = gltf.nodes.mesh_0.geometry
  const rota = 3.14*2;
  const styles = useSpring({
    loop: false,
    from: { rotation: [0, 3.14, 0 ],
             position: add_2_lists(props.position,[0,0,0]),
           },
    to: { rotation: [0, 3.14/2, 0],
          position: add_2_lists(props.position,[0,-5,0])
          },
    config:{ mass: 200, tension: 100, friction: 0 }
  });

  // console.log(gltf)

  const [hovered, sethovered] = useState(false)
  const [starter, setsstarter] = useState(false)
  const [colorfrom, setcolorfrom] = useState('#000000')
  const [counter, setcounter] = useState()
  const zep_col_dark = '#1060f0'
  const zep_col_light = '#2080e0'
  const [colorto, setto] = useState('#000000')
  useEffect(() => {

    if(props.clicked){
      sethovered(true)
    }
    let intervalId;
    let perc = 100;

    if((hovered)&&(starter)){
      setcounter(perc)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if (hovered && (starter==false)){
      setsstarter(true)
      setcounter(0)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if(starter){
      intervalId = setInterval(() => {
        setcounter(counter + 1);
      }, 1)
    }
    if (counter < perc){
      var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
      var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
      var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
      var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
      setcolorfrom(c)
    }else{
      setsstarter(false)
    }
   document.body.style.cursor = hovered ? "pointer" : "auto"
   return () => clearInterval(intervalId);
 }, [hovered,props.clicked,counter])
const material = new THREE.MeshPhongMaterial({
  color: colorfrom,    // red (can also use a CSS color string here)
  flatShading: false,
});
  return(
    <>
    <animated.mesh shadowMap
    castShadow
    receiveShadow { ...styles}>
          <mesh castShadow receiveShadow shadowMap
          geometry={gltf.nodes.mesh_0.geometry}
          scale={2}
          onPointerOver={(e) => (e.stopPropagation(), sethovered(true))}
          onPointerOut={(e) => (e.stopPropagation(), sethovered(false))}

          material={material}
           >
            </mesh>
      </animated.mesh>
    </>
  );
}
