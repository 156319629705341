/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/







import { useGLTF } from '@react-three/drei'
import React, { useRef, useState, useEffect } from 'react'
import * as THREE from 'three'
import { useSpring, animated } from '@react-spring/three'

import { useFrame} from '@react-three/fiber'
import { hextorgb, rgbtohex} from './miscelaneous'


export default function Car({ ...props }) {
  const group = useRef()
  const { nodes } = useGLTF('./car/scene.gltf')


  const [start] = useState(() => Math.random() * 5000)
  const [mixer] = useState(() => new THREE.AnimationMixer())



  useFrame((state, delta) => {
    const elap = state.clock.getElapsedTime()*props.speed+start;
    group.current.rotation.y = elap - 3.14/2 + 1.5 ;
    group.current.position.y = props.position[1]-10;
    group.current.position.x = -Math.sin(elap)*props.radius + props.position[0];
    group.current.position.z = -Math.cos(elap)*props.radius + props.position[2];

    mixer.update(delta*props.speed*2)
  });


  const [hovered, sethovered] = useState(false)
  const [starter, setsstarter] = useState(false)
  const [colorfrom, setcolorfrom] = useState('#000000')
  const [counter, setcounter] = useState()
  const zep_col_dark = '#07703c'
  const zep_col_light = '#94c93d'
  const [colorto, setto] = useState('#000000')
  useEffect(() => {

    let intervalId;
    let perc = 100;
    if(props.clicked){
      sethovered(true)
    }
    if((hovered)&&(starter)){
      setcounter(perc)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if (hovered && (starter===false)){
      setsstarter(true)
      setcounter(0)
      setto(zep_col_dark)
      setcolorfrom(zep_col_light)
    }
    if(starter){
      intervalId = setInterval(() => {
        setcounter(counter + 1);
      }, 1)
    }
    if (counter < perc){
      var r = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).r),parseInt(hextorgb(colorto).r),counter/perc)
      var g = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).g),parseInt(hextorgb(colorto).g),counter/perc)
      var b = THREE.MathUtils.lerp(parseInt(hextorgb(colorfrom).b),parseInt(hextorgb(colorto).b),counter/perc)
      var c = rgbtohex(parseInt(r),parseInt(g),parseInt(b))
      setcolorfrom(c)
    }else{
      setsstarter(false)
    }
   document.body.style.cursor = hovered ? "pointer" : "auto"
   return () => clearInterval(intervalId);
  }, [starter,colorfrom,colorto,hovered,props.clicked,counter])
  const material = new THREE.MeshPhongMaterial({
    color: colorfrom,    // red (can also use a CSS color string here)
    flatShading: true,

  });

  const materialRed = new THREE.MeshPhongMaterial({
    color: "#e60012",    // red (can also use a CSS color string here)
    flatShading: true,
  });
  const materialBlack = new THREE.MeshPhongMaterial({
    color: "#000000",    // red (can also use a CSS color string here)
    flatShading: true,
  });

  const styles = useSpring({
    loop: true,
    from: { rotation: [0, 0, 0],
             position: [0,.1,0],
           },
    to: { rotation: [-.1, .1, 0],
          position: [0,-.1,0],
          },
    config:{ mass: 100, tension: 100, friction: 0 }
  });






  let hover_over = (e) => {
    e.stopPropagation()
    sethovered(true)
  }
  let hover_off = (e) => {
    e.stopPropagation()
    sethovered(false)
  }




  return (
    <group ref={group} {...props} dispose={null}
    onPointerOver={(e) => hover_over(e)}
    onPointerOut={(e) => hover_off(e)}
    >
    <animated.mesh castShadow receiveShadow { ...styles}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={2}>
        <group position={[2.55, -5.13, 1.24]}>
          <mesh geometry={nodes.Circle_0.geometry} material={material} rotation={[0, 0, -Math.PI / 2+1]}/>
        </group>
        <group position={[2.61, 5.38, 1.14]}>
          <mesh geometry={nodes.Circle001_0.geometry} material={material} />
        </group>
        <group position={[-2.13, 5.38, 1.14]} rotation={[0, 0, -Math.PI]}>
          <mesh geometry={nodes.Circle002_0.geometry} material={material}  />
        </group>
        <group position={[-2.11, -5.13, 1.24]} rotation={[0, 0, -Math.PI]}>
          <mesh geometry={nodes.Circle003_0.geometry} material={material} rotation={[0, 0, -Math.PI / 2+1]} />
        </group>
        <group position={[1.23, 9.12, 2.86]} scale={0.08}>
          <mesh geometry={nodes.Cube001_0.geometry} material={materialRed} />
        </group>
        <group position={[-0.73, 9.12, 2.86]} scale={0.08}>
          <mesh geometry={nodes.Cube002_0.geometry} material={materialRed} />
        </group>
        <group position={[2.16, 0.04, 0.86]} scale={[0.64, 3.09, 0.18]}>
          <mesh geometry={nodes.Cube003_0.geometry} material={material} />
        </group>
        <group position={[0.25, -3.08, 4]} rotation={[-0.13, -0.18, 0.17]} scale={[0.06, 0.08, 0.16]}>
          <mesh geometry={nodes.Cube004_0.geometry} material={material} />
        </group>
        <group position={[0.25, -8.86, 2.1]} scale={0.05}>
          <mesh geometry={nodes.Cube005_0.geometry} material={materialBlack} />
        </group>
        <group position={[0.25, -8.69, 1.38]} scale={[0.76, 0.04, 0.26]}>
          <mesh geometry={nodes.Cube006_0.geometry} material={materialBlack} />
        </group>
        <group position={[2.55, -5.13, 1.24]} rotation={[0, Math.PI / 2, 0]} scale={1.32}>
          <mesh geometry={nodes.Cylinder_0.geometry} material={materialBlack} rotation={[Math.PI / 2-1, 0, 0]}/>
        </group>
        <group position={[2.55, -5.13, 1.24]} rotation={[0, Math.PI / 2, 0]} scale={0.06}>
          <mesh geometry={nodes.Cylinder001_0.geometry} material={materialBlack} rotation={[Math.PI / 2-1, 0, 0]}/>
        </group>
        <group position={[3.33, -5.13, 1.24]} rotation={[0, Math.PI / 2, 0]} scale={[0.66, 0.66, 0.1]}>
          <mesh geometry={nodes.Cylinder002_0.geometry} material={materialBlack} rotation={[Math.PI / 2-1, 0, 0]}/>
        </group>
        <group position={[3.39, 5.38, 1.14]} rotation={[0, Math.PI / 2, 0]} scale={[0.66, 0.66, 0.1]}>
          <mesh geometry={nodes.Cylinder005_0.geometry} material={materialBlack} rotation={[Math.PI / 2-1, 0, 0]}/>
        </group>
        <group position={[2.61, 5.38, 1.14]} rotation={[0, Math.PI / 2, 0]} scale={0.06}>
          <mesh geometry={nodes.Cylinder006_0.geometry} material={materialBlack} />
        </group>
        <group position={[2.61, 5.38, 1.14]} rotation={[0, Math.PI / 2, 0]} scale={1.32}>
          <mesh geometry={nodes.Cylinder007_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.13, 5.38, 1.14]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={1.32}>
          <mesh geometry={nodes.Cylinder008_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.13, 5.38, 1.14]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.06}>
          <mesh geometry={nodes.Cylinder009_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.91, 5.38, 1.14]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={[0.66, 0.66, 0.1]}>
          <mesh geometry={nodes.Cylinder010_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.27, 5.38, 1.14]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={1.32}>
          <mesh geometry={nodes.Cylinder011_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.25, -5.13, 1.24]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={1.32}>
          <mesh geometry={nodes.Cylinder012_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.89, -5.13, 1.24]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={[0.66, 0.66, 0.1]}>
          <mesh geometry={nodes.Cylinder013_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.11, -5.13, 1.24]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.06}>
          <mesh geometry={nodes.Cylinder014_0.geometry} material={materialBlack} />
        </group>
        <group position={[-2.11, -5.13, 1.24]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={1.32}>
          <mesh geometry={nodes.Cylinder015_0.geometry} material={materialBlack} rotation={[Math.PI / 2-1, 0, 0]}/>
        </group>
        <group position={[0.25, 8.88, 3.84]} rotation={[1.94, 0, 0]} scale={[0.05, 0.05, 0.05]}>
          <mesh geometry={nodes.Cylinder016_0.geometry} material={materialBlack} rotation={[Math.PI / 2-1, 0, 0]}/>
        </group>
        <group position={[2.52, 8, 1.24]} rotation={[Math.PI / 2, 0, 0]} scale={[0.39, 0.28, 0.49]}>
          <mesh geometry={nodes.Cylinder017_0.geometry} material={materialBlack} />
        </group>
        <group position={[0.25, -1.68, 2.64]} rotation={[0, Math.PI / 2, 0]}>
          <mesh geometry={nodes.Plane_0.geometry} material={material} />
        </group>
        <group position={[0.25, -1.68, 2.64]} rotation={[0, Math.PI / 2, 0]}>
          <mesh geometry={nodes.Plane001_0.geometry} material={nodes.Plane001_0.material} />
        </group>
        <group position={[0.25, -1.68, 2.64]} rotation={[0, Math.PI / 2, 0]}>
          <mesh geometry={nodes.Plane002_0.geometry} material={nodes.Plane002_0.material} />
        </group>
        <group position={[3.47, -4.62, 1.41]} rotation={[0, Math.PI / 2, 0]} scale={0.11}>
          <mesh geometry={nodes.Plane003_0.geometry} material={materialBlack} />
        </group>
        <group position={[3.54, 5.89, 1.31]} rotation={[0, Math.PI / 2, 0]} scale={0.11}>
          <mesh geometry={nodes.Plane004_0.geometry} material={materialBlack} />
        </group>
        <group position={[-3.05, 4.87, 1.31]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.11}>
          <mesh geometry={nodes.Plane005_0.geometry} material={materialBlack} />
        </group>
        <group position={[-3.03, -5.64, 1.41]} rotation={[Math.PI, -Math.PI / 2, 0]} scale={0.11}>
          <mesh geometry={nodes.Plane006_0.geometry} material={nodes.Plane006_0.material} />
        </group>
        <group position={[0.25, 9.12, 3.36]} rotation={[-1.19, 0, -Math.PI]} scale={[0.01, 0.01, 0.01]}>
          <mesh geometry={nodes.Plane007_0.geometry} material={nodes.Plane007_0.material} />
        </group>
        <group position={[0.25, -8.56, 2.62]} rotation={[1.47, 0, 0]} scale={[0.01, 0.01, 0.01]}>
          <mesh geometry={nodes.Plane009_0.geometry} material={nodes.Plane009_0.material} />
        </group>
        <group position={[0.25, -8.95, 2.1]} rotation={[Math.PI / 2, 0, 0]} scale={0.02}>
          <mesh geometry={nodes.Plane010_0.geometry} material={nodes.Plane010_0.material} />
        </group>
      </group>
      </animated.mesh>
    </group>
  )
}

useGLTF.preload('/scene.gltf')
