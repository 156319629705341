import React, { useRef, useState, useEffect } from 'react'
import { useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useSpring, useTransition, animated,config } from '@react-spring/three'

import {add_2_lists, hextorgb, rgbtohex} from './miscelaneous'

export default function Umbrella ({ ...props }) {
  const gltf = useGLTF('./umbrella/scene.gltf')

  const styles = useSpring({
    loop: true,
    from: { rotation: [0, .2, 0 ],
             position: [0,0,0],
           },
    to: { rotation: [0, 0, 0],
          position: [0,0,0]
          },
    config:{ mass: 200, tension: 200, friction: 0 }
  });
  // child.material.color.lerp(color.set(hovered === child.name ? "tomato" : "#202020").convertSRGBToLinear(), hovered ? 0.1 : 0.05)

  return(
    <group {...props} scale={1.2}>
    <animated.mesh { ...styles} >
        <primitive object={gltf.scene}
         scale={0.1}/>
    </animated.mesh>
    </group>
  );

}
